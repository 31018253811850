// COLOURS

$red: #990000;
$powder-blue: #beecff;
$dark-grey: #1b2429;
$silver: #dbe9ee;
$n3-red: #d20021;
$green: #44d177;
$bg-blue: #c5d1e0;


// BASE STYLES

body, html {
  //background: $bg-blue
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  font-family: "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: "Courier New", monospace; }


.root {
  flex-grow: 1;
  width: 100%; }

#root {
  min-height: 100%;
  align-items: center;
  //background: $bg-blue
  flex-grow: 1; }

* {
  box-sizing: border-box; }

.show {
  border: none;
  background: none; }

.hide {
  display: none;
  border: none;
  background: none; }


// DROPDOWN
.dropdowns {
  margin-top: 1.5rem; }

.dropdown-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  > div {
    width: 100%; } }

.first-choice {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  > div {
    width: 100%; } }

.extra-choices {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-right: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    > div {
      display: flex;
      flex-direction: row;
      flex-grow: 1; } } }

.container {
  box-shadow: none; }

.dropdown-delete {
  background: $red !important;
  color: #FFF !important;
  margin-top: 1.25rem !important;
  width: 64px !important;
  height: 100%; }


.form-control {
  width: 100%;
  margin-bottom: 1.25rem !important; }

.slick-slide {
  div div {
    outline: none; } }

.slick-list {
  width: 80%;
  margin: 0 auto !important; }

.arrow-icon {
  color: #1b2429; }

.slick-next, .slick-prev {
  width: 32px;
  height: 32px;
  top: 0 !important; }

.slick-prev {
  left: 0 !important; }

.slick-next {
  right: 0 !important; }
